import type { FC, RefObject } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import {
  Place,
  PlaceAutocompleteFieldProps,
  PlaceAutocompleteInput
} from './autocomplete/PlaceAutocompleteInput'
import { Box, StackProps, useDisclosure, useMergeRefs, VStack } from '@chakra-ui/react'
import Popover from '@/app/components/common/Popover'
import AutoCompleteResults from '@/app/components/common/autocomplete/AutoCompleteResults'
import { MapCenter } from '@/core/typings'

interface AddressSearchProps extends StackProps {
  center: MapCenter
  onUpdate: (place: Place & { lat: number; lng: number }) => void
  inputProps?: Omit<PlaceAutocompleteFieldProps, 'onUpdate' | 'origin'>
  inputRef?: RefObject<HTMLInputElement>
  isDisabled?: boolean
}

export const AddressSearch: FC<AddressSearchProps> = ({
  onUpdate,
  center,
  inputProps,
  inputRef: inputRefProp,
  isDisabled,
  ...props
}) => {
  const [places, setPlaces] = useState<Place[]>()
  const localInputRef = useRef<HTMLInputElement | null>(null)
  const inputRef = useMergeRefs(inputRefProp, localInputRef)

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (places?.length) {
      !isOpen && onOpen()
    } else {
      isOpen && onClose()
    }
  }, [isOpen, onClose, onOpen, places?.length])

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        setPlaces([])
        onClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  return (
    <>
      <Box />
      <VStack minH={'40px'} spacing={0} w="full" {...props}>
        <Popover
          contentProps={{
            maxWidth: 'calc(100vw - var(--chakra-space-12))',
            width: 'auto',
            minW: 'var(--chakra-sizes-xs)',
            sx: {
              '.chakra-popover__body': {
                p: 0
              }
            }
          }}
          initialFocusRef={localInputRef}
          placement="bottom-start"
          {...{ isOpen }}
          target={
            <PlaceAutocompleteInput
              isDisabled={isDisabled}
              onUpdate={setPlaces}
              origin={{ lat: center.lat, lng: center.lng }}
              ref={inputRef}
              {...inputProps}
            />
          }
          title="">
          <AutoCompleteResults
            onSelectItem={async (place) => {
              if (!place.lat || !place.lng) return
              setPlaces(undefined)
              onUpdate({ ...place, lat: place.lat!, lng: place.lng! })
              onClose()
            }}
            places={places}
            zIndex="docked"
          />
        </Popover>
      </VStack>
    </>
  )
}

export default AddressSearch
