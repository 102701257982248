import type { FC } from 'react'
import { HStack, StackDivider, StackProps, Text, VStack } from '@chakra-ui/react'
import { Place } from './PlaceAutocompleteInput'

interface AutoCompleteResultsProps extends StackProps {
  places?: Place[]
  onSelectItem: (place: Place) => void
}

export const AutoCompleteResults: FC<AutoCompleteResultsProps> = ({
  onSelectItem,
  places,
  ...props
}) => {
  return (
    <VStack
      divider={<StackDivider />}
      maxH="25vh"
      overflowY="auto"
      overscrollBehaviorY="contain"
      rounded="md"
      shadow={!!places?.length ? 'md' : 'none'}
      spacing={0}
      w="full"
      cursor="pointer"
      {...props}>
      {places?.map((place, index) => (
        <HStack
          bg="background.default"
          key={index}
          onClick={() => onSelectItem(place)}
          p={2}
          w="full">
          <Text flex={1} fontSize="sm" isTruncated>
            {place.formattedAddress}
          </Text>

          {place.distance ? (
            <Text fontSize="0.60rem" px={1} rounded="md">
              {place.distance}
            </Text>
          ) : null}
        </HStack>
      ))}
    </VStack>
  )
}

export default AutoCompleteResults
