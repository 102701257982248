'use client'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Flex, forwardRef, InputProps, Text, useMergeRefs } from '@chakra-ui/react'
import { BasePlace, PlaceType } from './typings'
import DebounceInput from '@/app/components/common/DebounceInput'
import { useLoadScript } from '@react-google-maps/api'
import { API_KEY } from '@/constants'

export type PlaceAutocompleteFieldProps = InputProps & {
  onUpdate: (places: Place[]) => void
  origin?: {
    lat: number
    lng: number
  }
}

export interface Place extends BasePlace {}

export const PlaceAutocompleteInput = forwardRef<PlaceAutocompleteFieldProps, 'input'>(
  ({ onUpdate, origin, ...props }: PlaceAutocompleteFieldProps, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const mergedInputRef = useMergeRefs(inputRef, ref)
    const divRef = useRef<HTMLDivElement | null>(null)

    const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey: API_KEY!,
      libraries: ['geometry', 'places']
    })
    const [autocomplete, setAutocomplete] = useState<google.maps.places.AutocompleteService | null>(
      null
    )
    const [places, setPlaces] = useState<Place[]>([])

    const convertPredictionIntoPlace = useCallback(
      (prediction: google.maps.places.AutocompletePrediction) => {
        if (!divRef.current || !isLoaded) return null
        const placesService = new window.google.maps.places.PlacesService(divRef.current)
        return new Promise((resolve, reject) => {
          placesService.getDetails({ placeId: prediction.place_id }, (res) => {
            if (!res) {
              reject('No se encontró la dirección')
              return
            }
            resolve(res)
          })
        }) as Promise<google.maps.places.PlaceResult>
      },
      [isLoaded]
    )

    useEffect(() => {
      if (!isLoaded) return
      if (inputRef?.current && !autocomplete) {
        const autocompleteInstance = new window.google.maps.places.AutocompleteService()
        if (!autocompleteInstance) return
        setAutocomplete(autocompleteInstance)
      }
    }, [autocomplete, inputRef, isLoaded])

    const fetchPlaces = useCallback(
      async (value: string) => {
        if (!autocomplete) return

        if (!value) {
          setPlaces([])
          return
        }

        const res = await autocomplete.getPlacePredictions({
          input: value,
          origin
        })

        const p: Place[] = []

        for (let i = 0; i < res.predictions.length; i++) {
          const pred = res.predictions[i]
          const place = await convertPredictionIntoPlace(pred)

          const distance = pred.distance_meters
            ? `${(pred.distance_meters / 1000).toFixed(2)} km`
            : undefined

          let type: PlaceType

          switch (true) {
            case pred.types.includes('country'):
              type = PlaceType.Country
              break
            case pred.types.includes('administrative_area_level_1'):
              type = PlaceType.AdministrativeArea
              break
            case pred.types.includes('locality'):
              type = PlaceType.Locality
              break
            case pred.types.includes('sublocality'):
            case pred.types.includes('sublocality_level_1'):
            case pred.types.includes('neighborhood'):
              type = PlaceType.Neighborhood
              break
            case pred.types.includes('route'):
              type = PlaceType.Route
              break
            case pred.types.includes('street_address'):
            case pred.types.includes('intersection'):
              type = PlaceType.Intersection
              break
            case pred.types.includes('premise'):
              type = PlaceType.Premise
              break
            case pred.types.some((type) =>
              ['point_of_interest', 'establishment', 'park', 'store'].includes(type)
            ):
              type = PlaceType.PointOfInterest
              break
            default:
              type = PlaceType.Unrecognized
          }

          p.push({
            id: pred.place_id,
            htmlStr: place?.adr_address,
            formattedAddress: place?.formatted_address,
            lat: place?.geometry?.location?.lat(),
            lng: place?.geometry?.location?.lng(),
            distance,
            type
          })
        }

        setPlaces(p)
      },
      [autocomplete, convertPredictionIntoPlace, origin]
    )

    const handleInputChange = useCallback(
      async (value: string) => {
        if (!value) {
          setPlaces([])
        }

        await fetchPlaces(value)
      },
      [fetchPlaces]
    )

    useEffect(() => {
      onUpdate(places)
    }, [onUpdate, places])

    if (!isLoaded) {
      return null
    }

    if (loadError) {
      return (
        <Flex alignItems="center" justifyContent="center" w="100vw" h="100vh">
          <Text color="red.400">Error al cargar el mapa: {loadError.message}</Text>
        </Flex>
      )
    }

    return (
      <>
        <DebounceInput
          fontSize="16px"
          onDebounce={handleInputChange}
          placeholder="Busca una dirección..."
          ref={mergedInputRef}
          {...props}
        />
        <Box ref={divRef} />
      </>
    )
  }
)
