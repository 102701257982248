export function propIfExists<T>(key: string, value: T | undefined): Partial<Record<string, T>> {
  return value !== undefined ? { [key]: value } : {}
}

export function arrayItemIf<T>(condition: boolean, itemGenerator: () => T[]): T[] {
  return condition ? itemGenerator() : []
}

export function pushIfConditionMet<T>(items: Array<{ item: T; condition: boolean }>): T[] {
  return items.reduce((acc: T[], currentItem) => {
    if (currentItem.condition) {
      acc.push(currentItem.item)
    }
    return acc
  }, [])
}
