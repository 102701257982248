import type { ChangeEvent } from 'react'
import { forwardRef } from 'react'
import type { InputProps } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import debounce from 'lodash/debounce'

interface DebounceInputProps extends InputProps {
  onDebounce: (val: string) => void
  debounceTimeout?: number
}

const DebounceInput = forwardRef<HTMLInputElement, DebounceInputProps>(
  ({ onDebounce, debounceTimeout, ...props }, ref) => {
    const d = debounce((value) => {
      onDebounce(value)
    }, debounceTimeout || 500)
    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => d(target.value)
    return <Input {...props} {...{ onChange, ref }} />
  }
)

DebounceInput.displayName = 'DebounceInput'

export default DebounceInput
