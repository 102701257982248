import { useEffect } from 'react'
import { Slide, toast } from 'react-toastify'
import LoadingToast from '@/app/components/common/LoadingToast/LoadingToast'

export const useLoadingToast = (toastId: string, isLoading = false) => {
  useEffect(() => {
    if (isLoading) {
      toast(<LoadingToast />, {
        toastId,
        position: 'bottom-right',
        autoClose: false,
        closeButton: false,
        draggable: false,
        hideProgressBar: true,
        transition: Slide
      })
    } else {
      toast.dismiss(toastId)
    }
  }, [isLoading, toastId])
}
